


























































































import { Dialog,Toast,Notify } from 'vant'
import ChangeMessage from "./ChangeMessage.vue"
import Stroage from '@/util/Storage'
import { DDTimes } from "@/util/time"
import { UserStore } from "@/store/modules/User"
import { CouponStore } from "@/store/modules/Basics/Coupon"
import { MyComponentType } from "@/Type/index"
import { ErrorCoupon,ChangeState } from '@/Api/Express/index'
import { BackOrder,BackPay,IssuePay,IssuePayBack } from '@/Api/Express/index'
import { Vue,Component,Prop,Emit,Ref } from "vue-property-decorator"
import {setPullData} from "@/util/compatibility_App";

// let WeixinJSBridge:any = ( "WeixinJSBridge" in window  ) ? window.WeixinJSBridge : {}; // {}
declare let WeixinJSBridge: any;
declare const document:any;

interface ExpressStateType<T=string>{
    userId:T|number;
    token:T;
    DownIcon:T;
    bikeIcon:T;
    copyIcon:T;
    OrderId:T;
    OrderData:any
    phoneIcon:T;
    Show:boolean;
    loadingShow:boolean;

    handleCopyPhone(str:string):void;
    handleChangeOrder():void;
    handleRecur():void;
    handleClickError():void;
    handleGoCoupon():void;
    handleChangeShow(data:boolean):void;
    handleClickRepeat():void;
    handleInformBike(data:string):void;
    handleBackOrder():void;
    handleBackPay():void;

    handleClickPay():void;
    handlePay():void;
    onBridgeReady():void;
    handleCallBack():void;
    handleError(data:string):void;
}

@Component({ name:"ExpressState",components:{ ChangeMessage } })
export default class ExpressState extends Vue implements ExpressStateType{
    loadingShow = false
    DownIcon = require("$icon/Basics/Down.png");
    bikeIcon = require("$icon/Express/Bike.png");
    copyIcon = require("$icon/Express/Copy.png");
    phoneIcon = require("$icon/Express/Phone.png");
    userId: string|number = "";
    token = "";
    OrderId = "";
    OrderData:any = {};
    Show = false;

    mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
       this.OrderId = this.$route.query.orderId as string

       // ChangeState({
       //         userId:this.userId,
       //         token:this.token
       //     },{
       //         ids:this.OrderId,
       //         status:5,
       //         errorMessage:"代取补偿券已经发放，请到我的--红包卡券查看！"
       //     })
    }

   handleCopyPhone(str:string){
       if( !str.length )return;
       this.GetCopy.value = str
       this.GetCopy.select()
       document.execCommand("copy");
       Toast({
           message:"复制成功",
           icon:"none",
           className:"ToastClassName"
       })
   }

   handleChangeOrder(){
       this.ChangeOrder({
           index:this.GetIndex,
           data:this.GetData
       })
   }

   handleRecur(){
       this.$router.replace({
           path:"/issueExpress"
       })
       UserStore.SetSelAddress({})
       CouponStore.SetSelCoupon({})
   }

   handleClickError(){
       Dialog.confirm({
           title: '温馨提醒',
           message: '您是选择 退款 或者 领取补偿卷?',
           confirmButtonText:"点击退款",
           cancelButtonText:"领取补偿卷",
           className:"HideClassName",
           overlayClass:"MaskHideClassName"
       }).then(res=>{
           // 点击退款
           BackPay({
               userId:this.userId,
               token:this.token
           },{
               orderId:this.OrderId
           }).then( ( res:any )=>{
               this.loadingShow = false
               if( res.message.code === "200" ){
                   Dialog.confirm({
                       title: '退款成功',
                       message: '尊敬的用户！请及时查看微信退款,如有疑问请联系我',
                       confirmButtonText:"知道了",
                       showCancelButton:false,
                       className:"HideClassName",
                       overlayClass:"MaskHideClassName"
                   }).then(res=>res).catch(cat=>cat)
                   this.Reftch()
               }else{
                   this.handleError( res.message.msg )
               }
           } )
       }).catch(cat=>{
           // 领取 补偿卷
           this.handleGoCoupon()
       })
   }

   handleGoCoupon(): void {
       this.loadingShow = true
       new Promise( ( reslove,reject )=>{
           ErrorCoupon({
               userId:this.userId,
               token:this.token
           },{
               orderId:this.OrderId
           }).then( ( res:any )=>{
              if( res.message.code === "200" ){
                  if( res.data ){
                      reslove( true )
                  }else{
                      reject( res )
                  }
              } else{
                  reject( res )
              }
           } )
       } ).then( (res:any)=>{
           ChangeState({
               userId:this.userId,
               token:this.token
           },{
               ids:this.OrderId,
               status:8,
               errorMessage:"代取补偿券已经发放，请到我的--红包卡券查看！"
           }).then( (res:any)=>{
               this.loadingShow = false
               if( res.message.code === "200" ){
                   Dialog.confirm({
                       title: '温馨提醒',
                       message: '您已经领取了优惠卷',
                       confirmButtonText:"点击查看",
                       cancelButtonText:"点击留下",
                       className:"HideClassName",
                       overlayClass:"MaskHideClassName"
                   }).then( res=>{
                       this.$router.push({
                           path:"/couponList"
                       })
                   } ).catch( cat=>cat )
                   this.Reftch()
               }else{
                   this.handleError( res.message.msg )
               }
           } )
       } ).catch(cat=>{
           this.loadingShow = false
           this.handleError( cat.message.msg )
       })
   }

   handleChangeShow(data:boolean){
        this.Show = data
   }

   handleClickRepeat(){
       Dialog.confirm({
           title: '温馨提醒',
           message: '将通知配送员重新取件，是否修改备注留言？',
           confirmButtonText:"确定",
           cancelButtonText:"不用了",
           className:"HideClassName",
           overlayClass:"MaskHideClassName"
       }).then(res=>{
           this.Show = true
       }).catch(cat=>{
           this.handleInformBike('')
       })
   }

   handleInformBike(data:string): void {
       this.loadingShow = true
       ChangeState({
           userId:this.userId,
           token:this.token
       },{
           ids:this.GetData.id as string,
           status:2,
           errorMessage:data.length ? data : "已经通知配送员再次取件，请耐心等待！"
       }).then( res=>{
           this.loadingShow = false
           if( res.message.code === "200" ){
               Dialog.confirm({
                   title: '温馨提醒',
                   message: '已通知快递员,优先领取您的快递',
                   confirmButtonText:"知道了",
                   showCancelButton:false,
                   className:"HideClassName",
                   overlayClass:"MaskHideClassName"
               }).then(res=>res).catch( cat=>cat )
               this.Reftch()
           }else{
               this.handleError( res.message.msg )
           }
       } )
   }

   handleBackOrder(): void {
       new Promise( (reslove,reject)=>{
           Dialog.confirm({
               title: '温馨提示',
               message: '尊敬的用户您确定要 取消订单么？',
               confirmButtonText:"确定取消",
               cancelButtonText:"考虑一下",
               className:"HideClassName",
               overlayClass:"MaskHideClassName"
           }).then( (res)=>{
               reslove(true)
           } ).catch(cat=>{
               reject()
           })
       } ).then( res=>{
           this.loadingShow = true
           BackOrder({
               userId:this.userId,
               token:this.token
           },{
               orderId:this.OrderId //this.GetData.id || 0 //
           }).then( ( res:any )=>{
               this.loadingShow = false
               if( res.message.code === "200" ){
                   Toast({
                       message:"取消成功",
                       icon:"none",
                       className:"ToastClassName"
                   })
                   this.Reftch()
               }else{
                   this.handleError( res.message.msg )
               }
           } )
       } ).catch(cat=>cat)
   }

   handleBackPay(): void {
       new Promise( (reslove,reject)=>{
           Dialog.confirm({
               title: '温馨提示',
               message: '尊敬的用户您确定要 退款么？',
               confirmButtonText:"确定退款",
               cancelButtonText:"考虑一下",
               className:"HideClassName",
               overlayClass:"MaskHideClassName"
           }).then( (res)=>{
               reslove(true)
           } ).catch(cat=>{
               reject()
           })
       } ).then( res=>{
           this.loadingShow = true
           BackPay({
               userId:this.userId,
               token:this.token
           },{
               orderId:this.OrderId
           }).then( ( res:any )=>{
               this.loadingShow = false
               if( res.message.code === "200" ){
                   Dialog.confirm({
                       title: '退款成功',
                       message: '尊敬的用户！请及时查看微信退款,如有疑问请联系我',
                       confirmButtonText:"知道了",
                       showCancelButton:false,
                       className:"HideClassName",
                       overlayClass:"MaskHideClassName"
                   }).then(res=>res).catch(cat=>cat)
                   this.Reftch()
               }else{
                   this.handleError( res.message.msg )
               }
           } )
       } ).catch(cat=>cat)
   }

   handleClickPay(){
       let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
       if ( bool != null && bool[0] == "micromessenger" ){
           this.loadingShow = false
           setPullData({ orderId:this.OrderId })
           return
       }
       this.loadingShow = true
       IssuePay({
           userId:this.userId,
           token:this.token
       },{
           orderId:this.OrderId
       }).then( (res:any)=>{
           if( res.message.code === "200" ){
               this.OrderData = res.data
               this.handlePay()
           }else{
               this.loadingShow = false
               this.handleError( res.message.msg )
           }
       } )
   }

   handlePay(){
       if ( "WeixinJSBridge" in window  && typeof WeixinJSBridge == "undefined"){
           if( document.addEventListener ){
               document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
           }else if ( "attachEvent" in document ){
               try{
                   document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
                   document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
               }catch (e) {
                   console.info("事件不存在" + e)
               }
           }
       }else{
           this.onBridgeReady();
       }
   }

   onBridgeReady(){
       ( "invoke" in WeixinJSBridge) && WeixinJSBridge.invoke(
           'getBrandWCPayRequest', {
               "appId":this.OrderData.appId,//公众号名称，由商户传入
               "timeStamp":this.OrderData.timeStamp,//时间戳，自1970年以来的秒数
               "nonceStr":this.OrderData.nonceStr, //随机串
               "package":this.OrderData.package,
               "signType":this.OrderData.signType,//微信签名方式：
               "paySign":this.OrderData.paySign //微信签名
           },
           (res:any)=>{
               if(res.err_msg == "get_brand_wcpay_request:ok" ){
                   this.handleCallBack()
               }
           });
   }

   handleCallBack(){
       IssuePayBack({
           userId:this.userId,
           token:this.token
       },{
           orderId:this.OrderId
       }).then(res=>{
           this.loadingShow = false
           if (res.message.code === "200") {
               Toast({
                   message:"支付成功",
                   icon:"none",
                   className:"ToastClassName"
               })
               this.Reftch()
           }else{
               this.handleError( res.message.msg )
           }
       })
   }

   handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }

   get FitlerTime(){
       return DDTimes( this.GetData.updateTime as string )
   }

   get FitlerState():string{
       switch( Number(this.GetData.status) ){
           case 0:
               return "去支付";
           case 1:
               return "待接单";
           case 2:
               return "待取货";
           case 3:
               return "待送达";
           case 4:
               return "已完成";
           case 5:
               return "订单异常";
           case 6:
               return "已取消";
           case 7:
               return "已删除";
           default:
               return "啥也不是"
       }
   }

   get OrderStateStr():string{
       switch ( Number( this.GetData.status ) ) {
           case 0:
               return '您的订单还未支付';
           case 1:
               return '您的订单已经下单成功，等待配送员接单';
           case 2:
               return '您的订单已经接单，等待配送员取货';
           case 3:
               return '您的快递配送员已取走,等待送达';
           case 4:
               return '取货序号:'+ this.GetData.sequence || '' + '(快递三上记号笔标记的排列序号)';
           case 5:
               return this.GetData.errorMessage || '订单异常中';
           case 6:
               return '取货序号:您的订单已经取消,感谢使用三易校园';
           case 7:
               return '您的订单已经退款,感谢使用三易校园';
           case 8:
               return "已领取补偿卷";
           default:
               return '啥也不是'
       }
   }

   @Ref("Copy")
   Copy!:HTMLInputElement;
   get GetCopy(){
       return this.Copy
   }

    @Prop(Object)
    data!:MyComponentType

    get GetData(){
        return this.data
    }

    @Prop(Number)
    index!:number

    get GetIndex(){
        if( this.index ){
            return this.index
        }else{
            return 0
        }
    }

    @Emit("Reftch")
    Reftch(){
        return true
    }

    @Emit("ChangeOrder")
    ChangeOrder(data:{ index:number,data:MyComponentType }){
        return data
    }
}
