

































































import { Toast,Notify,Dialog } from 'vant'
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import HeadCom from "@/components/ExpressComponent/ExpressSuc/Head.vue"
import ExpressState from "@/components/ExpressComponent/ExpressSuc/ExpressState.vue"
import ChangeOrder from "@/components/ExpressComponent/ExpressSuc/ChangeOrderSuc.vue"
import Stroage from '@/util/Storage'
import { EXPRESSSUCTYPE,MyComponentType } from '@/Type/index'
import { ExpressSuc as UpSuc } from '@/Api/Express/index'
import { Vue,Component } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";
import {isGroupChat} from "@/server";


interface ItemType<T>{
    title:T;
    content:T;
    color:T;
}

interface ExpressSucType<T=string>{
    userId:T|number;
    token:T;
    loadingShow:boolean
    ChatIcon:T;
    WarnIcon:T;
    ContList:Array<ItemType<T>>
    OrderId:T;
    Id:T;
    OrderData:any
    Show:boolean;
    ChangeData:MyComponentType;
    data:EXPRESSSUCTYPE;

    handleComplaint():void;
    // hanleContact():void;
    handleChangeShow(data?:any):void;
    handleReftchData(bool?:boolean):void;
    handleUpData():void;
    handleFilter():void;
    handleFitlerState(str:T|number):T;
    handleError(data:string):void;
}

@Component({ name:"ExpressSuc",components:{ ChangeOrder,HeadCom,ExpressState,HeadTop } })
export default class ExpressSuc extends ZoomPage implements ExpressSucType{
   userId: string|number = "";
   token = "";
   loadingShow = true;
   ChatIcon = require("$icon/Express/Chat.png");
   OffIcon = require("$icon/Express/Off.png");
   WarnIcon = require("$icon/Express/warning.png")
   ContList:Array<ItemType<string>> = [
       {
           title:`付款价格`,
           content:"1.28元（总价: 1.28元 · 优惠0元）",
           color:"",// 默认为黑色字体
       },
       {
           title:"留言备注",
           content:"暂无留言备注",
           color:"#e7c31b",// 默认为黑色字体
       },
       {
           title:"异常备注",
           content:"暂无异常备注",
           color:"#f45c57",// 默认为黑色字体
       },
       {
           title:"订单状态",
           content:"已取消状态",
           color:"",// 默认为黑色字体
       },
       {
           title:"送达时间",
           content:"17:15 - 18:15",
           color:"",// 默认为黑色字体
       },
       {
           title:"下单时间",
           content:"2020-5-21 14:15:45",
           color:"",// 默认为黑色字体
       },
       {
           title:"更新时间",
           content:"2020-5-21 14:15:45",
           color:"",// 默认为黑色字体
       },
   ]
   OrderId = ""
   Id = ""
   OrderData:any = {}
   Show = false;
   ChangeData:MyComponentType = {}
   data: EXPRESSSUCTYPE<string> = {}

    activated() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
       this.OrderId = this.$route.query.orderId as string
       this.Id = this.$route.query.id as string

       this.handleUpData()
   }

   handleChangeShow(data?:any){
       this.ChangeData = data.data
       this.Show = !this.Show
   }

   handleReftchData( bool:boolean ){
       this.Show = !this.Show
       if( bool ){
           this.handleUpData()
       }
   }

   handleUpData(){
       UpSuc({
           userId:this.userId,
           token:this.token
       },{
           orderId:this.OrderId as string
       }).then( (res:any)=>{
           this.loadingShow = false
           if( res.message.code === "200" ){
               this.data = res.data
               this.handleFilter()
           }else{
               this.handleError( res.message.msg )
           }
       } )
   }

   handleFilter(){
       this.ContList[0].content = `总价: ${ this.data.amount }元 · 优惠${ this.data.couponAmount }元` || "啥也不是"
       this.ContList[1].content = this.data.remark as string || "暂无留言备注"
       this.ContList[2].content = this.data.errorMessage as string || "暂无异常备注"
       this.ContList[3].content = this.handleFitlerState( this.data.status ) || "啥也不是"
       this.ContList[4].content = this.data.receiveDate as string || "啥也不是"
       this.ContList[5].content = this.data.createTime as string || "啥也不是"
       this.ContList[6].content = this.data.updateTime as string || "啥也不是"
   }

   handleContact(){
       let id = process.env.NODE_ENV === "development" ? "2" : '18'
       isGroupChat(id).then(res=>{
           let { name:title = "",userId:groupHost = "" } = res;
           let query:any = {
               id,
               title,
               groupHost,
               state:"groupChat",
               type:"expFeedback",
           }
           if ( groupHost != Stroage.GetData_?.("userId") ){
               query["orderData"] = JSON.stringify(this.data)
           }
           this.$router.push({
               name:"chat",
               query
           })
       })
   }

   handleComplaint(){
       Dialog.confirm({
           title: '温馨提示',
           message: '是否拨打投诉电话？',
           confirmButtonText:"确定",
           cancelButtonText:"取消",
           className:"HideClassName",
           overlayClass:"MaskHideClassName"
       }).then( (res:any)=>{
           let A = document.createElement("a");
           A.href = "tel:17324818788"
           A.click()
       } ).catch( cat=>cat )
   }

   handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }

   handleFitlerState(str:string|number):string{
       switch( Number(str) ){
           case 0:
               return "去支付";
           case 1:
               return "待接单";
           case 2:
               return "待取货";
           case 3:
               return "待送达";
           case 4:
               return "已完成";
           case 5:
               return "订单异常";
           case 6:
               return "已取消";
           case 7:
               return "已删除";
           default:
               return "啥也不是"
       }
   }
}
