








































































 import { Notify,Toast } from 'vant'
 import Stroage from "@/util/Storage"
 import SelPullItem from "@/components/ShareComponent/PullSelItem.vue"
 import { GetFromType,MyComponentType } from "@/Type/index"
 import { FilterPullItem } from '@/util/filter'
 import { ChangeUpDataType as UpDataType } from '@/Api/Express/interfaceType'
 import { StoreExpress } from "@/store/modules/Express/Express"
 import { GetFrom,ChangeOrder } from "@/Api/Express/index"
 import { Vue,Component,Prop,Watch,Emit } from "vue-property-decorator"

//  interface UpDataType<T>{
//      orderId:T;
//      mailCompany:T;
//      mailCode:T;
//      phone:T;
//      name:T;
//      remark:T;
//  }

 interface ChangeOrderSucType<T=string>{
     loadingShow:boolean;
     FromIndex:number;
     OldIndex:number;
     FromList:GetFromType[];
     name:T;
     phone:T;
     code:T;
     message:T;
     userId:T|number;
     token:T;
     UpData:UpDataType<T>
     Show:boolean;
 
     initData():void;
     handleToggleShow():void;
     handlePullClick(data:any):void;
     handlChangeClick():void;
     handleError(data:string):void;
 }

 @Component({ name:"ChangeOrderSuc",components:{ SelPullItem } })
 export default class ChangeOrderSuc extends Vue implements ChangeOrderSucType{
    loadingShow = false
    FromIndex = 0
    OldIndex = 0;
    FromList: GetFromType[] = []
    name = ""
    phone = ""
    code = ""
    message = ""
    userId:string|number = ""
    token = ""
    Show = false
    UpData: UpDataType<string> = {
        orderId:"",
        mailCompany:"",
        mailCode:"",
        phone:"",
        name:"",
        remark:"",
    }
    
    mounted() {
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")
    }

    initData(){
        if( StoreExpress.GetFromList.length ){
            this.FromList = FilterPullItem(StoreExpress.GetFromList,"name") as Array<GetFromType> 
            let reg = new RegExp( this.GetData.mailCompany as string )
            let Index = this.FromList.findIndex( a=>reg.test(a.name) )
            if( Index < 0 )Index = 0;
            this.FromIndex = Index;
            this.OldIndex = Index;
            this.UpData.mailCompany = this.GetData.mailCompany as string
        }else{
            this.handleFrom()
        }
        this.name = this.GetData.name as string
        this.UpData.name = this.name
        this.phone = this.GetData.phone as string
        this.UpData.phone = this.phone
        this.code = this.GetData.mailCode as string
        this.UpData.mailCode = this.code
        this.message = this.GetData.remark as string
        this.UpData.remark = this.message

        this.UpData.orderId = this.GetData.orderId as string // mainOrderId orderId
    }

    handleFrom(): void {
        this.loadingShow = true
        GetFrom({
            userId:this.userId,
            token:this.token
        }).then((res:any)=>{
            this.loadingShow = false
            console.log( res )
            if ( res.message.code === "200" ) {
                    this.FromList = FilterPullItem(res.data,"name") as Array<GetFromType>
                    this.UpData.mailCompany = this.FromList[0].mailCompany as string

                    let reg = new RegExp( this.GetData.mailCompany as string )
                    let Index = this.FromList.findIndex( a=>reg.test(a.name) )
                    if( Index < 0 )Index = 0;
                    this.FromIndex = Index;
                    this.OldIndex = Index;

                    StoreExpress.SetFromList(res.data)
            }else{
                this.handleError( res.message.msg )
            }
        })
    }

    handleToggleShow(){
        this.ChangeShow(false)
    }

    handlePullClick(data?:any){
        this.FromIndex = data.index
    }

    handlChangeClick(){
        let { name,code,phone,message } = this
        let reg = / /g
        name = name.replace(reg,"")
        code = code.replace(reg,"")
        phone = phone.replace(reg,"")
        message = message.replace(reg,"")
        let Num = 0;
        if( this.GetData.mailCompany != this.FromList[this.FromIndex].name ){
            Num++;
            this.UpData.mailCompany = this.FromList[this.FromIndex].name
        }
        if( this.GetData.name != this.name ){
            Num ++;
            this.UpData.name = this.name
        }
        if( this.GetData.mailCode != this.code ){
            Num ++;
            this.UpData.mailCode = this.code
        }
        if( this.GetData.phone != this.phone ){
            Num ++;
            this.UpData.phone = this.phone
        }
        if( this.GetData.remark != this.message ){
            Num ++;
            this.UpData.remark = this.message
        }
        if( Num <= 0 ){
            this.handleError("请修改订单信息在提交内容")
            return
        }
        this.loadingShow = true
        ChangeOrder({
            userId:this.userId,
            token:this.token
        },this.UpData).then( (res:any)=>{
            console.log( res )
            this.loadingShow = false
            if( res.message.code === "200" ){
                Toast({
                    message:"修改成功",
                    icon:"none",
                    className:"ToastClassName"
                })
            
                this.ChangeShow(true)
            }else{
                this.handleError(res.message.msg)
            }
        } )
    }

    handleError( data:string ){
        let str = ""
        if ( data.length ){
            str = data
        }else{
            str = "网络繁忙..."
        }
        Notify({
            message: str,
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });            
    }

    @Prop(Object)
    data!:MyComponentType
    get GetData(){
        return this.data
    }

    @Watch("GetData")
    handleGetData(newVal:any){
        this.initData()
    }

    @Prop(Boolean)
    show!:boolean

    get GetShow(){
        return this.show
    }

    @Watch("GetShow")
    handleGetShow(newVal:boolean){
        this.Show = newVal
        if( this.Show ){
            document.body.style.overflow = "hidden"
        }else{
            document.body.style.overflow = "auto"
        }
    }

    @Emit("ChangeShow")
    ChangeShow(bool?:boolean){
        return bool
    }

 }
