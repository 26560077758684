

























import { Vue,Component, Prop, Emit } from "vue-property-decorator"

interface ChangeMessageType<T=string>{
    Value:T;
    Show:boolean

    handleClickShow():void;
    handleClickQue():void;
}

@Component({ name:"ChangeMessage" })
export default class ChangeMessage extends Vue implements ChangeMessageType{
    Value = ""
    Show = false

    handleClickShow(){
        this.pullShow( false )
    }

    handleClickQue(){
        this.pullShow( true )
        this.pullMessage( this.Value )
        this.Value = ""
    }

    @Prop(Boolean)
    show!:boolean

    get GetShow(){
        return this.show
    }

    @Emit("pullShow")
    pullShow(data:boolean){
        return data
    }

    @Emit("pullMessage")
    pullMessage(data:string){
        return data
    }
}
