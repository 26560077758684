


























import { Toast } from "vant"
import Obj from "@/util/Storage"
import { Vue,Component,Prop,Ref } from "vue-property-decorator"

interface ExpSucHeadType<T=string>{
    putIcon:T;
    copyIcon:T;
    phoneIcon:T;

    handleCopyPhone(str:string):void;
}

 @Component({ name:"ExpSucHead" })
 export default class ExpSucHead extends Vue implements ExpSucHeadType{
    putIcon = require("$icon/Express/Put.png");
    copyIcon = require("$icon/Express/Copy.png");
    phoneIcon = require("$icon/Express/Phone.png")

     handleCopyPhone(str:string){
        if( !str.length )return;
        this.GetCopy.value = str
        this.GetCopy.select()
        document.execCommand("copy");
        Toast({
            message:"复制成功",
            icon:"none",
            className:"ToastClassName"
        })
    }

     @Prop(Object)
     data!:any;

     get GetData(){
         return this.data
     }

     @Ref("Copy")
    Copy!:HTMLInputElement;
    get GetCopy(){
        return this.Copy
    }
 }
